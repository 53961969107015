<template>
  <div class="pointsMall_container" :style="`background: ${info.bj_color}`">
    <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div>
    <div class="content">
      <div class="title">当前积分商城商品</div>
      <div class="goods_wrap">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="goods_img">
            <img :src="item.image.url" alt="" />
          </div>
          <div class="flex_d_c_c">
            <p class="c3 f16">{{ item.title }}</p>
            <p class="jifen">兑换积分：{{ item.points }}分</p>
            <p class="c9">剩余数量：{{ item.number }}</p>
            <div class="btn flex_c_c c_p" :class="[item.can_exchange == 101 ? 'green' : 'grey']" @click="handleExchange(item)">
              {{ item.btn_str }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="积分兑换" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form">
        <el-form-item label="所需积分：">
          <span>{{ points }}</span>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="form.shr" placeholder="收货人" class="w300" />
        </el-form-item>
        <el-form-item label="收货电话：">
          <el-input v-model="form.shdh" placeholder="收货电话" class="w300" />
        </el-form-item>
        <el-form-item label="收货地址：">
          <el-input v-model="form.shdz" placeholder="收货地址" class="w300" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { handleJump, getZoneInfo } from "@/utils";

export default {
  data() {
    return {
      handleJump,
      list: [],
      dialogVisible: false,
      form: {
        prize_id: "", //积分商品id
        shr: "",
        shdh: "",
        shdz: "",
      },
      info: {},
      points: 0,
    };
  },
  created() {
    this.$api("zone.getPoints").then((res) => {
      this.list = res.data;
    });
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
  },

  methods: {
    handleExchange(item) {
      if (item.can_exchange == 102) return;
      if (item.jfsplx == 102) {
        this.$api("zone.ExchangePoints", { prize_id: item.id }).then(() => {
          this.dialogVisible = false;
          this.$message.success("兑换成功");
        });
      } else {
        this.form.prize_id = item.id;
        this.points = item.points;
        this.dialogVisible = true;
      }
    },
    submit() {
      if (!this.form.shr || !this.form.shdh || !this.form.shdz) return;
      this.$api("zone.ExchangePoints", this.form).then(() => {
        this.dialogVisible = false;
        this.$message.success("兑换成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pointsMall_container {
  background-size: cover;
}
.w300 {
  width: 300px;
}
.img {
  width: 100%;
  height: 375px;
}
.title {
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-left: 20px;
  line-height: 58px;
  box-sizing: border-box;
  margin: 10px 0;
}
.goods_wrap {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 230px;
    height: 370px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 10px;
    box-sizing: border-box;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .goods_img {
      width: 210px;
      height: 200px;
      background: #ffffff;
      border-radius: 4px;
    }
    .jifen {
      font-size: 16px;
      font-weight: bold;
      color: #f93232;
      margin: 14px 0;
    }
    .btn {
      width: 100px;
      height: 32px;
      border-radius: 4px;
      color: #fff;
      margin-top: 15px;
    }
    .green {
      background: @themeColor;
    }
    .grey {
      background-color: #eee;
      color: #333;
    }
  }
}
</style>
